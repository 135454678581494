import { defineStore } from "pinia";
import { useApi } from "@/use/api/useApi";
import { applyFullNameToList } from "@/utils/formatter/nameFormatter";

import { computed, ref } from "vue";
import { ROLE_BLOCKED_USER } from "@/const/roles";
import type { ManagerI } from "@/stores/auth/UserInterface";

const useUsersStore = defineStore('usersStore', () => {

  const usersList = ref<ManagerI[]>([])
  const activeUsers = computed(() => usersList.value.filter(m => m.role?.id !== ROLE_BLOCKED_USER))

  const usersLoading = ref(false);
  const usersError = ref(false);

  async function getUsers(query: string) {
    usersError.value = false;
    usersLoading.value = true;

    return useApi().users.fetchUsers<ManagerI[]>(query || '')
      .then((data: ManagerI[]) => {
        usersList.value = applyFullNameToList<ManagerI>(data)
      })
      .catch(() => {
        usersList.value = []
        usersError.value = true;
      })
      .finally(() => usersLoading.value = false)
  }

  function resetStore() {
    usersList.value = []
    usersError.value = false;
    usersLoading.value = false;
  }

  return {
    usersLoading,
    usersList,
    usersError,
    activeUsers,
    getUsers,
    resetStore,
  }
}, { persist: true })

export default useUsersStore;
